<template>
  <div class="content">
    <div>
      <div class="create">
        <div class="row align-items-center">
          <div class="col-4">
            <select v-model="command" class="form-select">
              <option value="sync:posts">Sync Blog</option>
              <option value="sync:products">Sync Products</option>
              <option value="sync:coupons">Sync Coupons</option>
              <option value="sync:shipping">Sync Shipping Methods</option>
              <option value="sync:categories">Sync Categories</option>
              <option value="sync:terms">Sync Attributes</option>
              <option value="sync:everything">Sync Everything</option>
              <option value="tag:images">Tag Application Images</option>
              <option value="sync:marketing-contacts">Push contacts to Newsletter Provider (Mailchimp or AC)</option>
            </select>
          </div>
          <div class="col-2">
            <button class="vave-btn btn-green" @click="createSynchronization()">Start</button>
          </div>
          <div class="col">
            <div class="connection" :class="{'connected': clientConnected}"></div>
          </div>
          <div class="col-auto">
            <button class="vave-btn btn-blue" @click="getSynchronizations()">
              <div class="d-flex align-items-center">
                <i class="fa fa-sync me-1"></i> Refresh
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="table-container">
        <table class="table">
          <thead class="head">
            <tr>
              <th class="ps-4">Id</th>
              <th>Type</th>
              <th>Arguments</th>
              <th>Started at</th>
              <th>Finished at</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="synchronization in synchronizationsList.data" :key="synchronization.id">
              <td class="ps-4">{{synchronization.id}}</td>
              <td>{{synchronization.type}}</td>
              <td>{{synchronization.arguments}}</td>
              <td>{{synchronization.started_at}}</td>
              <td>{{synchronization.finished_at}}</td>
              <td>
                <span
                  class="badge"
                  :class="{
                  'bg-secondary': synchronization.status == 'pending',
                  'bg-primary': synchronization.status == 'in_progress',
                  'bg-success': synchronization.status == 'succeeded',
                  'bg-danger': synchronization.status == 'failed',
                }"
                >{{synchronization.status}}</span>
              </td>
              <td>
                <div class="d-flex">
                  <div class="vave-btn btn-small ms-2" @click="showOutput(synchronization.id), showWidgetPicker()" title="show job output">
                    <i class="fa fa-eye"></i>
                  </div>
                  <div class="vave-btn btn-small btn-green ms-2" @click="retryJob(synchronization.type)" title="retry job">
                    <i class="fa fa-sync"></i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination class="paginator" :limit="4" align="center" :data="synchronizationsList" @pagination-change-page="getSynchronizations"></pagination>
      </div>
    </div>

    <widget-config-modal ref="widgetPicker">
      <template v-slot:title>Output</template>
      <template v-slot:body>
        <div style="border:1px solid black;padding: 20px;">
          <div style="overflow: auto;">
            <span style="white-space: pre;">{{output}}</span>
          </div>
        </div>
      </template>
    </widget-config-modal>
  </div>
</template>

<script>
import MqttClient from "./../mqtt.js";
import EventBus from "./../bus.js";
import axios from "../http.js";
import pagination from "laravel-vue-pagination";
import WidgetConfigModal from "./widgets/WidgetConfigModal";

export default {
  data() {
    return {
      output: "",
      command: "sync:posts",
      synchronizationsList: {},
      totalSynchronizations: 0,
    };
  },
  components: { WidgetConfigModal, pagination },
  computed: {
    clientConnected() {
      return MqttClient.getClient().connected;
    },
  },
  mounted() {
    if (Object.keys(this.synchronizationsList).length <= 0) {
      this.getSynchronizations();
    }
    let topic = "globus/vaveproxy/synchronizations/#";
    MqttClient.subscribe(topic);
    EventBus.$on(topic, this.getSynchronizations);
  },
  beforeDestroy() {
    EventBus.$off("globus/vaveproxy/synchronizations/#", this.getSynchronizations);
  },
  methods: {
    showWidgetPicker() {
      this.$refs.widgetPicker.show();
    },
    retryJob(type) {
      if (!confirm('do you want to retry "' + type + '"?')) {
        return;
      }
      this.pushJob(type);
    },
    showOutput(id) {
      this.output = "";
      axios.get("/api/admin/synchronizations/" + id).then((res) => {
        if (res.data) {
          this.output = this.formatOutput(res.data.output);
        }
      });
    },
    formatOutput(data) {
      return data;
    },
    getSynchronizations(page = 1, perPage = 10) {
      this.output = "";
      this.synchronizationsList = {};
      axios
        .get(
          "/api/admin/synchronizations?page=" + page + "&per_page=" + perPage
        )
        .then((response) => {
          if (response.data.data) {
            this.synchronizationsList = response.data;
          }
        });
    },
    createSynchronization() {
      if (!confirm('do you want to start do "' + this.command + '"?')) {
        return;
      }
      this.pushJob(this.command);
    },
    pushJob(type) {
      axios.post("/api/admin/synchronizations", { type: type }).then(
        () => {
          this.getSynchronizations();
        },
        (e) => {
          console.log(e.response.data);
          alert(e.response.data.message);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
.create {
  padding-bottom: 20px;
}
.connection {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
  float: right;
  position: relative;
  top: 5px;
}
.connected {
  background: green;
}
</style>